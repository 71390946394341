import React from 'react';
import { Card as BsCard, Col, Row } from 'react-bootstrap';

const Card = ({ title, actions, children, id, bodyClassName = 'p-0' }) => {
  return (
    <BsCard>
      <BsCard.Header className="bg-light" id={id}>
        <Row className="flex-between-center">
          {title && (
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              {title}
            </Col>
          )}
          <Col
            xs={title ? 8 : 12}
            sm="auto"
            className={`ms-auto text-end${title ? ' ps-0' : ''}`}
          >
            <div id="orders-actions">{actions}</div>
          </Col>
        </Row>
      </BsCard.Header>
      <BsCard.Body className={bodyClassName}>{children}</BsCard.Body>
    </BsCard>
  );
};

export default Card;
