export const assertions = [
  {
    assertion: 'equal',
    value: true,
  },
  {
    assertion: 'notEqual',
    value: true,
  },
  {
    assertion: 'isTrue',
    value: false,
  },
  {
    assertion: 'isNotTrue',
    value: false,
  },
  {
    assertion: 'isFalse',
    value: false,
  },
  {
    assertion: 'isNotFalse',
    value: false,
  },
  {
    assertion: 'exists',
    value: false,
  },
  {
    assertion: 'notExists',
    value: false,
  },
  {
    assertion: 'isOk',
    value: false,
  },
  {
    assertion: 'isNotOk',
    value: false,
  },
  {
    assertion: 'strictEqual',
    value: true,
  },
  {
    assertion: 'notStrictEqual',
    value: true,
  },
  {
    assertion: 'deepEqual',
    value: true,
  },
  {
    assertion: 'notDeepEqual',
    value: true,
  },
  {
    assertion: 'isAbove',
    value: true,
  },
  {
    assertion: 'isAtLeast',
    value: true,
  },
  {
    assertion: 'isBelow',
    value: true,
  },
  {
    assertion: 'isAtMost',
    value: true,
  },
  {
    assertion: 'isNull',
    value: false,
  },
  {
    assertion: 'isNotNull',
    value: false,
  },
  {
    assertion: 'isNaN',
    value: false,
  },
  {
    assertion: 'isNotNaN',
    value: false,
  },
  {
    assertion: 'isUndefined',
    value: false,
  },
  {
    assertion: 'isDefined',
    value: false,
  },
  {
    assertion: 'isFunction',
    value: false,
  },
  {
    assertion: 'isNotFunction',
    value: false,
  },
  {
    assertion: 'isObject',
    value: false,
  },
  {
    assertion: 'isNotObject',
    value: false,
  },
  {
    assertion: 'isArray',
    value: false,
  },
  {
    assertion: 'isNotArray',
    value: false,
  },
  {
    assertion: 'isString',
    value: false,
  },
  {
    assertion: 'isNotString',
    value: false,
  },
  {
    assertion: 'isNumber',
    value: false,
  },
  {
    assertion: 'isNotNumber',
    value: false,
  },
  {
    assertion: 'isFinite',
    value: false,
  },
  {
    assertion: 'isBoolean',
    value: false,
  },
  {
    assertion: 'isNotBoolean',
    value: false,
  },
  {
    assertion: 'include',
    value: true,
  },
  {
    assertion: 'notInclude',
    value: true,
  },
  {
    assertion: 'deepInclude',
    value: true,
  },
  {
    assertion: 'notDeepInclude',
    value: true,
  },
  {
    assertion: 'nestedInclude',
    value: true,
  },
  {
    assertion: 'notNestedInclude',
    value: true,
  },
  {
    assertion: 'deepNestedInclude',
    value: true,
  },
  {
    assertion: 'notDeepNestedInclude',
    value: true,
  },
  {
    assertion: 'ownInclude',
    value: true,
  },
  {
    assertion: 'notOwnInclude',
    value: true,
  },
  {
    assertion: 'deepOwnInclude',
    value: true,
  },
  {
    assertion: 'notDeepOwnInclude',
    value: true,
  },
  {
    assertion: 'match',
    value: true,
  },
  {
    assertion: 'notMatch',
    value: true,
  },
  {
    assertion: 'property',
    value: true,
  },
  {
    assertion: 'notProperty',
    value: true,
  },
  {
    assertion: 'nestedProperty',
    value: true,
  },
  {
    assertion: 'notNestedProperty',
    value: true,
  },
  {
    assertion: 'lengthOf',
    value: true,
  },
  {
    assertion: 'hasAnyKeys',
    value: true,
  },
  {
    assertion: 'hasAllKeys',
    value: true,
  },
  {
    assertion: 'containsAllKeys',
    value: true,
  },
  {
    assertion: 'doesNotHaveAnyKeys',
    value: true,
  },
  {
    assertion: 'doesNotHaveAllKeys',
    value: true,
  },
  {
    assertion: 'doesNotHaveAllKeys',
    value: true,
  },
  {
    assertion: 'hasAllDeepKeys',
    value: true,
  },
  {
    assertion: 'containsAllDeepKeys',
    value: true,
  },
  {
    assertion: 'doesNotHaveAnyDeepKeys',
    value: true,
  },
  {
    assertion: 'doesNotHaveAllDeepKeys',
    value: true,
  },
  {
    assertion: 'sameMembers',
    value: true,
  },
  {
    assertion: 'notSameMembers',
    value: true,
  },
  {
    assertion: 'sameDeepMembers',
    value: true,
  },
  {
    assertion: 'notSameDeepMembers',
    value: true,
  },
  {
    assertion: 'sameOrderedMembers',
    value: true,
  },
  {
    assertion: 'notSameOrderedMembers',
    value: true,
  },
  {
    assertion: 'sameDeepOrderedMembers',
    value: true,
  },
  {
    assertion: 'notSameDeepOrderedMembers',
    value: true,
  },
  {
    assertion: 'includeMembers',
    value: true,
  },
  {
    assertion: 'notIncludeMembers',
    value: true,
  },
  {
    assertion: 'includeDeepMembers',
    value: true,
  },
  {
    assertion: 'notIncludeDeepMembers',
    value: true,
  },
  {
    assertion: 'includeOrderedMembers',
    value: true,
  },
  {
    assertion: 'notIncludeOrderedMembers',
    value: true,
  },
  {
    assertion: 'includeDeepOrderedMembers',
    value: true,
  },
  {
    assertion: 'notIncludeDeepOrderedMembers',
    value: true,
  },
  {
    assertion: 'oneOf',
    value: true,
  },
  {
    assertion: 'isEmpty',
    value: false,
  },
  {
    assertion: 'isNotEmpty',
    value: false,
  },
];

export const propertyAs = ['age', 'number', 'string'];

export const isIterableArray = (array) => {
  return Array.isArray(array) && !!array.length;
};
