import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

export const SET_VALUES_ACTION = '@@REDUX_JSON_FORM/SET_VALUES';
export const CLEAR_SYNC_ERRORS = '@@REDUX_JSON_FORM/CLEAR_SYNC_ERRORS';

export const setReduxJsonFormValues = (form, data) => {
  return {
    type: SET_VALUES_ACTION,
    data,
    meta: { form },
  };
};

export const clearSyncErrors = (form) => {
  return {
    type: CLEAR_SYNC_ERRORS,
    data: {},
    meta: { form },
  };
};

export const reduxJsonFormReducer = (state, action) => {
  if (action.type === SET_VALUES_ACTION) {
    const form = action && action.meta && action.meta.form;
    if (!form || !state[form] || !state[form].values) return state;
    return reduxFormReducer(
      {
        ...state,
        [form]: {
          ...state[form],
          values: {
            ...state[form].values,
            ...action.data,
          },
        },
      },
      action,
    );
  }
  if (action.type === CLEAR_SYNC_ERRORS) {
    const form = action && action.meta && action.meta.form;
    if (!form || !state[form] || !state[form].values) return state;
    return reduxFormReducer(
      {
        ...state,
        [form]: {
          ...state[form],
          syncErrors: {},
        },
      },
      action,
    );
  }
  return reduxFormReducer(state, action);
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const allReducers = combineReducers({
  form: reduxJsonFormReducer,
});

export const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunk)),
);

const WithStore = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

WithStore.propTypes = {
  children: PropTypes.object.isRequired,
};

export default WithStore;
