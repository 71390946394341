import React, { useEffect, useState } from 'react';
import { PaulAdminContext } from 'services/paul_admin/context';
import axios from 'axios';
import { useSession } from 'services/sessions/hook';
import { getBrands } from 'requests/brands';
import EnvironmentSelectField from 'components/environment/select_field';
import { PAUL_DOMAINS } from 'constants';
export default function PaulAdminProvider({ children }) {
  //get env in search params

  const { currentSessions } = useSession();
  const [env, setEnv] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brandsForEnv, setBrandsForEnv] = useState([]);
  const [adminData, setAdminData] = useState({});
  console.log('currentSessions', currentSessions);
  const init = (env) => {
    if (env) {
      getBrands(env).then((res) => {
        setBrandsForEnv(res.data.brands);
      });
    }
  };

  useEffect(() => {
    init(env);
  }, [env]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const env = searchParams.get('env');
    setEnv(env);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (brandsForEnv.length > 0) {
      let admin = {};
      const promises = brandsForEnv.map((brand) => {
        return axios
          .get(PAUL_DOMAINS[env].path + `admin`, {
            params: { brand: brand.slug },
            auth: {
              username: process.env.REACT_APP_DEV_USERNAME,
              password: process.env.REACT_APP_DEV_PASSWORD,
            },
          })
          .then((response) => {
            admin[brand.slug] = response.data;
          });
      });
      Promise.all(promises).then((res) => {
        setAdminData(admin);
        setLoading(false);
      });
    }
  }, [brandsForEnv]);

  console.log('adminData', adminData);

  return (
    <PaulAdminContext.Provider value={{ adminData, loading }}>
      <EnvironmentSelectField env={env} setEnv={setEnv} loading={loading} />
      {children}
    </PaulAdminContext.Provider>
  );
}
