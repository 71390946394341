import React, { useEffect, useState } from 'react';
import FirebaseContext from 'services/firebase/context';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  collection,
} from 'firebase/firestore';

const FirebaseProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [db, setDb] = useState(null);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    setFirebaseApp(app);
    setDb(firestore);
    setInitialized(true);
  }, []);

  const createSchemaPreview = async (schema) => {
    if (!db) return null;
    const docRef = await addDoc(collection(db, 'schema_preview'), {
      schema,
    });
    return docRef.id;
  };

  const getSchemaPreview = async (id) => {
    if (!db) return null;
    const docRef = doc(db, 'schema_preview', id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  };

  if (!initialized) return null;

  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        getSchemaPreview,
        createSchemaPreview,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
