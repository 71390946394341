import compact from 'lodash/compact';
export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
export const COOKIES_EXPIRES_IN_DAYS = 60;
export const DEVELOPMENT = process.env.NODE_ENV === 'development';
export const PRODUCTION = process.env.NODE_ENV === 'production';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const PROTOCOL = process.env.REACT_APP_PROTOCOL;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const APPSIGNAL_API_KEY = process.env.REACT_APP_APPSIGNAL_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const BYPASS_MAINTENANCE_COOKIE_NAME =
  process.env.REACT_APP_BYPASS_MAINTENANCE_COOKIE_NAME;

export const DATA_BE_API = process.env.REACT_APP_DATA_BE_API;
export const CARAPI_DOMAIN = process.env.REACT_APP_CARAPI_DOMAIN;
export const EUROTAX_DOMAIN = process.env.REACT_APP_EUROTAX_DOMAIN;
export const EUROTAX_YUZZU_DOMAIN = process.env.REACT_APP_EUROTAX_YUZZU_DOMAIN;
export const EUROTAX_YUZZU_TCAR8_DOMAIN =
  process.env.REACT_APP_EUROTAX_YUZZU_TCAR8_DOMAIN;
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
export const ALGOLIA_APPLICATION_ID =
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID;

export const NODE_ENV = process.env.NODE_ENV;

export const DEFAULT_ENV = process.env.REACT_APP_DEFAULT_ENV;

export const ENV_DOMAINS = compact([
  DEVELOPMENT && { env: 'development', domain: 'insurgate.test' },
  { env: 'staging', domain: 'insurgate-staging.com' },
  { env: 'validation', domain: 'insurgate-validation.com' },
  { env: 'production', domain: 'insurgate.com' },
]);

export const API_DOMAINS = {
  development: 'https://api.insurgate.test/',
  staging: 'https://api.insurgate-staging.com/',
  validation: 'https://api.insurgate-validation.com/',
  production: 'https://api.insurgate.com/',
};

export const SESSION_ENVS = {
  development: 'DEV',
  staging: 'STAGING',
  validation: 'VALIDATION',
  production: 'PRODUCTION',
};

export const PAUL_DOMAINS = {
  development: {
    path: 'https://paul.insurgate.test/',
  },
  staging: {
    path: 'https://paul.insurgate-staging.com/',
  },
  validation: {
    path: 'https://paul.insurgate-validation.com/',
  },
  production: {
    path: 'https://paul.insurgate.com/',
  },
};

export const LANSKY_ENVS = {
  development: {
    path: 'https://paul.insurgate.test/lansky',
  },
  staging: {
    path: 'https://paul.insurgate-staging.com/lansky',
  },
  validation: {
    path: 'https://paul.insurgate-validation.com/lansky',
  },
  production: {
    path: 'https://paul.insurgate.com/lansky',
  },
};

export const WATSON_ENVS = {
  development: {
    path: 'https://paul.insurgate.test/watson',
  },
  staging: {
    path: 'https://paul.insurgate-staging.com/watson',
  },
  validation: {
    path: 'https://paul.insurgate-validation.com/watson',
  },
  production: {
    path: 'https://paul.insurgate.com/watson',
  },
};

export const LOCALES = {
  fr: 'fr',
  nl: 'nl',
};

export const SELECT_STYLES = {
  container: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  singleValue: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  input: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  placeholder: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '28.82px',
  }),
  control: (provided) => ({
    ...provided,
    width: '350px',
    height: '28.82px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '14px',
    minHeight: '28.82px',
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontSize: '14px',
  }),
};
