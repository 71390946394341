import React, { useEffect, useState } from 'react';
import { useVariableUsage } from 'services/variable_usage/hook';
import WorkflowVariableUsageUsage from 'components/workflow/variable_usage/usage';

const VariableUsageTable = ({ path }) => {
  const { getVariableUsages } = useVariableUsage();
  const [searches, setSearches] = useState([]);

  useEffect(() => {
    const searches = [];

    if (path.match(/[._](\d+)[\._]/)) {
      const pathArray = path.split(/[._](\d+)[\._]/);
      const pathToSearch = pathArray[pathArray.length - 1];
      searches.push({
        path: pathToSearch,
        results: getVariableUsages(pathToSearch),
      });
    }
    searches.push({
      path,
      results: getVariableUsages(path),
    });
    setSearches(searches);
  }, []);
  if (!searches.length) return null;

  return (
    <>
      {searches.map(({ path, results }) => (
        <div key={path}>
          <p>
            Results for <code className="fs--1">{path}</code>
          </p>
          <WorkflowVariableUsageUsage usage={results} />
        </div>
      ))}
    </>
  );
};

export default VariableUsageTable;
