import React, { useState, useEffect, useRef } from 'react';
import VariableUsageContext from 'services/variable_usage/context';
import {
  getBlocksOfKind,
  getEmailsOfBlocks,
  getStepsAvailableData,
  getWatsonInputs,
  getWorkflowLanskysInputs,
} from 'services/schema/schema';
import { filterOnlyLinkedDocumentTemplatesIds } from 'services/workflow/provider';
import { flatData } from 'services/flat_data';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { useSession } from 'services/sessions/hook';
import Loader from 'components/ui/loader';
import { useRequest } from 'services/request/hook';
import { getWorkflow } from 'requests/workflow';
import { parseLansky, getComputer } from 'services/schema/parse_lansky';
import countBy from 'lodash/countBy';
import get from 'lodash/get';
import values from 'lodash/values';
import every from 'lodash/every';

const count = (str, variablePath) => {
  const variablePattern = new RegExp(`${variablePath}`, 'g');
  let count = 0;
  if (
    variablePath.endsWith('.fr') ||
    variablePath.endsWith('.nl') ||
    variablePath.endsWith('.en')
  ) {
    count =
      count +
      (
        (str || '').match(variablePath.substring(0, variablePath.length - 3)) ||
        []
      ).length;
  }
  return count + ((str || '').match(variablePattern) || []).length;
};

const searchInEmailPart = (part, variablePath) => {
  const usagesByLocale = {};
  Object.keys(part).map((locale) => {
    const usageCount = count(part[locale], variablePath);
    usagesByLocale[locale] = usageCount;
  });
  if (every(values(usagesByLocale), (usageCount) => usageCount === 0)) {
    return null;
  }
  return usagesByLocale;
};

const VariableUsageProvider = ({ children }) => {
  const { currentSessions } = useSession();
  const [keys, setKeys] = useState([]);
  const [keysCount, setKeysCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [workflowData, setWorkflowData] = useState(null);
  //const [documentTemplateIds, setDocumentTemplateIds] = useState([]);
  const workflowDataRef = useRef(null);
  const documentTemplateIdsRef = useRef(null);
  const [lanskyInputs, setLanskyInputs] = useState({});
  const [watsonInputs, setWatsonInputs] = useState({});
  const [workflowValidationInputs, setWorkflowValidationInputs] = useState([]);
  const [schemaBlocks, setSchemaBlocks] = useState([]);
  const [emails, setEmails] = useState({});
  const [translations, setTranslations] = useState({});
  const { request, env } = useRequest();

  const getAvailableVariables = () => {
    return getStepsAvailableData(
      workflowData.workflow.steps,
      {},
      env,
      currentSessions,
    ).then(async (parse) => {
      const flatParse = flatData(parse);
      setKeys(sortBy(Object.keys(flatParse)));
      setKeysCount(Object.keys(flatParse).length);
    });
  };

  const initializeWorkflowData = () => {
    return getWorkflow(request.workflow_id, env)
      .then((response) => {
        const linkedDocTemplateIds = filterOnlyLinkedDocumentTemplatesIds(
          response.data,
        );
        documentTemplateIdsRef.current = linkedDocTemplateIds;
        workflowDataRef.current = response.data;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getLanskyInputs = () => {
    if (!isEmpty(lanskyInputs)) {
      return lanskyInputs;
    }
    return getWorkflowLanskysInputs(
      workflowDataRef.current.workflow,
      {},
      env,
      currentSessions,
    ).then((lanskyInputs) => {
      setLanskyInputs(lanskyInputs);
    });
  };

  const getWorkflowValidationInputs = async () => {
    if (!workflowDataRef.current.workflow_validation?.computer) {
      return;
    }
    const { inputs } = await parseLansky(
      getComputer(workflowDataRef.current.workflow_validation),
      env,
      currentSessions,
    );
    setWorkflowValidationInputs(inputs);
  };

  const getWatsonTemplatesInputs = () => {
    return getWatsonInputs(
      workflowDataRef.current.workflow.steps,
      {},
      workflowDataRef.current.document_templates.filter((doc) =>
        documentTemplateIdsRef.current.includes(doc.id),
      ),
    ).then((watsonInputs) => {
      const notEmptyBlockSlugs = Object.keys(watsonInputs).filter(
        (blockSlug) => !isEmpty(watsonInputs[blockSlug]),
      );
      setWatsonInputs(() => {
        const inputs = {};
        notEmptyBlockSlugs.forEach((blockSlug) => {
          inputs[blockSlug] = watsonInputs[blockSlug];
        });
        return inputs;
      });
    });
  };

  const getEmailsOfSteps = () => {
    const blockEmails = getEmailsOfBlocks(
      workflowDataRef.current.workflow.steps,
      workflowDataRef.current.emails,
    );
    setEmails(blockEmails);
  };

  const getFormBlocks = () => {
    const formBlocks = getBlocksOfKind(
      workflowDataRef.current.workflow.steps,
      'form',
    );
    const premiumsTableBlocks = getBlocksOfKind(
      workflowDataRef.current.workflow.steps,
      'premiums_table',
    );
    setSchemaBlocks([...formBlocks, ...premiumsTableBlocks]);
  };

  const getTranslations = () => {
    const translations = get(
      workflowDataRef.current,
      'workflow.translations',
      {},
    );
    setTranslations(translations);
  };

  const init = async () => {
    setLoading(true);
    try {
      //await getAvailableVariables();
      await initializeWorkflowData();
      await getWorkflowValidationInputs();
      await getLanskyInputs();
      await getWatsonTemplatesInputs();
      await getEmailsOfSteps();
      await getFormBlocks();
      await getTranslations();
    } catch (e) {
      console.error('error while init', e);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [request.workflow_id]);

  const getVariableUsages = (variablePath) => {
    const workflowValidationsInputCount = countBy(
      workflowValidationInputs,
      (item) => item === variablePath,
    );
    const usages = {
      lansky: [],
      watson: [],
      emails: [],
      blocks: [],
      contexts: [],
      translations: [],
      workflow_validation: workflowValidationInputs.filter(
        (input) => input === variablePath,
      ).length,
    };
    /*
      console.group('getVariableUsages');
      console.log('variablePath', variablePath);
      console.log('lanskyInputs', lanskyInputs);
      console.log('watsonInputs', watsonInputs);
      console.log('workflowValidationInputs', workflowValidationInputs);
      console.log('emails', emails);
      console.log('schemaBlocks', schemaBlocks);
      console.groupEnd();
      */
    workflowDataRef.current.computers?.map((contextComputer) => {
      const inputs = contextComputer.active_version_inputs.inputs || [];
      if (inputs.includes(variablePath)) {
        usages.contexts.push({
          name: contextComputer.name,
          context: contextComputer,
        });
      }
    });
    Object.keys(translations).map((locale) => {
      const translationsForLocale = Object.entries(translations[locale]);
      translationsForLocale.forEach(([key, value], index) => {
        if (value.includes(variablePath)) {
          usages.translations.push({ locale, translation: key });
        }
      });
    });
    Object.keys(lanskyInputs).map((lanskySlug) => {
      const { inputs, computer } = lanskyInputs[lanskySlug];
      if (inputs.includes(variablePath)) {
        usages.lansky.push({ slug: lanskySlug, computer });
      }
    });
    Object.keys(watsonInputs).map((blockSlug) => {
      watsonInputs[blockSlug].forEach(
        ({ inputs: templateInputs, template }) => {
          const usedInputs = templateInputs.filter((input) => {
            const usageCount = count(input.raw, variablePath);
            return usageCount > 0;
          });
          if (!isEmpty(usedInputs)) {
            usages.watson.push({ slug: blockSlug, template });
          }
        },
      );
    });

    Object.keys(emails).map((blockSlug) => {
      emails[blockSlug].map((email) => {
        const {
          body_translations,
          recipient_translations,
          subject_translations,
        } = email;
        const bodyUsages = searchInEmailPart(body_translations, variablePath);
        const recipientUsages = searchInEmailPart(
          recipient_translations,
          variablePath,
        );
        const subjectUsages = searchInEmailPart(
          subject_translations,
          variablePath,
        );
        if (
          every(
            [bodyUsages, recipientUsages, subjectUsages],
            (usage) => usage === null,
          )
        ) {
          return;
        }
        usages.emails.push({
          slug: blockSlug,
          email,
          usages: {
            body: bodyUsages,
            recipient: recipientUsages,
            subject: subjectUsages,
          },
        });
      });
    });

    schemaBlocks.forEach((block) => {
      const schema = JSON.stringify(get(block, 'form_schema.schema', {}));
      const schemaCount = count(schema, variablePath);
      if (schemaCount > 0) {
        usages.blocks.push(block);
      }
    });

    return usages;
  };

  return (
    <VariableUsageContext.Provider
      value={{
        loading,
        keys,
        keysCount,
        lanskyInputs,
        watsonInputs,
        emails,
        init,
        count,
        getVariableUsages,
      }}
    >
      {loading ? <Loader /> : children}
    </VariableUsageContext.Provider>
  );
};

export default VariableUsageProvider;
