import {
  GOOGLE_API_KEY,
  PROTOCOL,
  API_DOMAIN,
  DATA_BE_API,
  CARAPI_DOMAIN,
  EUROTAX_DOMAIN,
  EUROTAX_YUZZU_DOMAIN,
  EUROTAX_YUZZU_TCAR8_DOMAIN,
  ALGOLIA_API_KEY,
  ALGOLIA_APPLICATION_ID,
} from 'constants';
import { getAuthorizationToken } from 'services/sessions/authorization_token';
import { PRODUCTION } from 'constants';

const t = (key) => key;
const _t = t;

export const getFormLabels = () => {
  return {
    renderingErrors: {
      message: _t(
        'components.ui.json_form.form_labels.renderingErrors.message',
      ),
    },
    formErrors: {
      title: _t('components.ui.json_form.form_labels.form_errors.title'),
      see: _t('components.ui.json_form.form_labels.form_errors.see'),
      title_success: _t(
        'components.ui.json_form.form_labels.form_success_title',
      ),
    },
    validations: {
      required: _t(
        'components.ui.json_form.form_labels.form_validations.required',
      ),
      date_invalid: _t(
        'components.ui.json_form.form_labels.form_validations.date_invalid',
      ),
      min: (params) => {
        return _t(
          'components.ui.json_form.form_labels.form_validations.min',
          params,
        );
      },
      max: (params) =>
        _t('components.ui.json_form.form_labels.form_validations.max', params),
      max_length: (params) =>
        _t(
          'components.ui.json_form.form_labels.form_validations.max_length',
          params,
        ),
      min_length: (params) =>
        _t(
          'components.ui.json_form.form_labels.form_validations.min_length',
          params,
        ),
      only_int: _t(
        'components.ui.json_form.form_labels.form_validations.only_int',
      ),
      only_letter: _t(
        'components.ui.json_form.form_labels.form_validations.only_letter',
      ),
      email_required: _t(
        'components.ui.json_form.form_labels.form_validations.email_required',
      ),
      email_invalid: _t(
        'components.ui.json_form.form_labels.form_validations.email_invalid',
      ),
      niss: {
        character_error: _t(
          'components.ui.json_form.form_labels.form_validations.niss.character_error',
        ),
        wrong_number: _t(
          'components.ui.json_form.form_labels.form_validations.niss.wrong_number',
        ),
        referent_date_invalid: _t(
          'components.ui.json_form.form_labels.form_validations.niss.referent_date_invalid',
        ),
        referent_date_not_match: _t(
          'components.ui.json_form.form_labels.form_validations.niss.referent_date_not_match',
        ),
      },
      phone_number: {
        invalid: _t(
          'components.ui.json_form.form_labels.form_validations.phone_number.invalid',
        ),
      },
    },
    fields: {
      algolia: {
        labels: {
          search_label: _t(
            'components.ui.json_form.form_labels.fields.algolia.labels.search_label',
          ),
          show_more: _t(
            'components.ui.json_form.form_labels.fields.algolia.labels.show_more',
          ),
          show_less: _t(
            'components.ui.json_form.form_labels.fields.algolia.labels.show_less',
          ),
          vivium_activities: {
            sector: _t(
              'components.ui.json_form.form_labels.fields.algolia.labels.vivium_activities.sector',
            ),
            division: _t(
              'components.ui.json_form.form_labels.fields.algolia.labels.vivium_activities.division',
            ),
            nace: _t(
              'components.ui.json_form.form_labels.fields.algolia.labels.vivium_activities.nace',
            ),
            keywords: _t(
              'components.ui.json_form.form_labels.fields.algolia.labels.vivium_activities.keywords',
            ),
          },
        },
      },
      carapi: {
        labels: {
          brand: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.brand',
          ),
          model: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.model',
          ),
          detailed_model: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.detailed_model',
          ),
          fuel_type: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.fuel_type',
          ),
          power: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.power',
          ),
          engine_displacement: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.engine_displacement',
          ),
          build_year: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.build_year',
          ),
          genre: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.genre',
          ),
          febiac_id: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.febiac_id',
          ),
          catalog_price: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.catalog_price',
          ),
          doors: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.doors',
          ),
          seats: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.seats',
          ),
          change_vehicle: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.change_vehicle',
          ),
          select_car_button: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.select_car_button',
          ),
          loading: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.loading',
          ),
          select_this_version: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.select_this_version',
          ),
          no_vehicle_found_for_those_parameters: _t(
            'components.ui.json_form.form_labels.fields.carapi.labels.no_vehicle_found_for_those_parameters',
          ),
        },
        placeholders: {
          brands: _t(
            'components.ui.json_form.form_labels.fields.carapi.placeholders.brands',
          ),
          models: _t(
            'components.ui.json_form.form_labels.fields.carapi.placeholders.models',
          ),
          powers: _t(
            'components.ui.json_form.form_labels.fields.carapi.placeholders.powers',
          ),
          fuel_types: _t(
            'components.ui.json_form.form_labels.fields.carapi.placeholders.fuel_types',
          ),
        },
      },
      eurotax_yuzzu: {
        labels: {
          brand: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.brand',
          ),
          model: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.model',
          ),
          fuel_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.fuel_type',
          ),
          power: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.power',
          ),
          transmission_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.transmission_type',
          ),
          vehicle: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.vehicle',
          ),
          version: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.version',
          ),
          name: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.name',
          ),
          hp: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.hp',
          ),
          kw: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.kw',
          ),
          value_without_tax: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.value_without_tax',
          ),
          select_this_version: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.select_this_version',
          ),
          door: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.door',
          ),
          engine_capacity: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.engine_capacity',
          ),
          nat_code: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.nat_code',
          ),
          seat: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.seat',
          ),
          change_vehicle: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.change_vehicle',
          ),
          select_car_button: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.select_car_button',
          ),
          select_car_modal_title: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.select_car_modal_title',
          ),
          no_vehicle_found_for_those_parameters: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.no_vehicle_found_for_those_parameters',
          ),
          loading: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.loading',
          ),
          date_of_first_circulation: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.date_of_first_circulation',
          ),
          production_dates: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.production_dates',
          ),
          kilo_watts: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.kilo_watts',
          ),
          horsepower: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.horsepower',
          ),
          body_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.body_type',
          ),
          build_year: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.labels.build_year',
          ),
        },
        placeholders: {
          brand: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.brand',
          ),
          model: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.model',
          ),
          fuel_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.fuel_type',
          ),
          transmission_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.transmission_type',
          ),
          date_of_first_circulation: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.date_of_first_circulation',
          ),
          body_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.body_type',
          ),
          build_year: _t(
            'components.ui.json_form.form_labels.fields.eurotax_yuzzu.placeholders.build_year',
          ),
        },
      },
      eurotax: {
        labels: {
          risk_type: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type',
          ),
          risk_types: {
            car: _t(
              'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type_car',
            ),
            van: _t(
              'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type_van',
            ),
            minibus: _t(
              'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type_minibus',
            ),
            oldtimer: _t(
              'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type_oldtimer',
            ),
            mobilhome: _t(
              'components.ui.json_form.form_labels.fields.eurotax.labels.risk_type_mobilhome',
            ),
          },
          eurotaxID: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.eurotaxID',
          ),
          brandId: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.brandId',
          ),
          brandLabel: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.brandLabel',
          ),
          modelId: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.modelId',
          ),
          modelLabel: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.modelLabel',
          ),
          fuelType: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.fuelType',
          ),
          buildYear: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.buildYear',
          ),
          constructionYear: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.constructionYear',
          ),
          fuelTypeEurotaxID: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.fuelTypeEurotaxID',
          ),
          fuelTypeName: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.fuelTypeName',
          ),
          fuelTypeMainframeID: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.fuelTypeMainframeID',
          ),
          vehicleTypeCode: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.vehicleTypeCode',
          ),
          taxHP: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.taxHP',
          ),
          bodyType: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.bodyType',
          ),
          segmentTypeInternational: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.segmentTypeInternational',
          ),
          segmentTypeNational: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.segmentTypeNational',
          ),
          sportCarCat: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.sportCarCat',
          ),
          powerKW: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.powerKW',
          ),
          parsed_kw: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.powerKW',
          ),
          powerHP: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.powerHP',
          ),
          powerHybKW: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.powerHybKW',
          ),
          transmissionId: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.transmissionId',
          ),
          transmissionLabel: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.transmissionLabel',
          ),
          tractionId: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.tractionId',
          ),
          tractionLabel: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.tractionLabel',
          ),
          gears: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.gears',
          ),
          seats: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.seats',
          ),
          seatMax: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.seatMax',
          ),
          doors: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.doors',
          ),
          amountCatalogueValueWithOutOptionsExclVAT: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.amountCatalogueValueWithOutOptionsExclVAT',
          ),
          amountCatalogueValueWithOutOptionsExclVATStartDate: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.amountCatalogueValueWithOutOptionsExclVATStartDate',
          ),
          amountCatalogueValueWithOutOptionsExclVATEndDate: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.amountCatalogueValueWithOutOptionsExclVATEndDate',
          ),
          version: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.version',
          ),
          typeVehicleId: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.typeVehicleId',
          ),
          typeVehicleName: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.typeVehicleName',
          ),
          concatOutput: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.concatOutput',
          ),
          select_this_version: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.select_this_version',
          ),
          no_vehicle_found_for_those_parameters: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.no_vehicle_found_for_those_parameters',
          ),
          change_vehicle: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.change_vehicle',
          ),
          select_car_button: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.select_car_button',
          ),
          select_car_modal_title: _t(
            'components.ui.json_form.form_labels.fields.eurotax.labels.select_car_modal_title',
          ),
        },
        placeholders: {
          brands: _t(
            'components.ui.json_form.form_labels.fields.eurotax.placeholders.brands',
          ),
          models: _t(
            'components.ui.json_form.form_labels.fields.eurotax.placeholders.models',
          ),
          powers: _t(
            'components.ui.json_form.form_labels.fields.eurotax.placeholders.powers',
          ),
          fuel_types: _t(
            'components.ui.json_form.form_labels.fields.eurotax.placeholders.fuel_types',
          ),
        },
      },
      address: {
        search: _t('components.ui.json_form.form_labels.fields.address.search'),
        street_name: _t(
          'components.ui.json_form.form_labels.fields.address.street_name',
        ),
        street_number: _t(
          'components.ui.json_form.form_labels.fields.address.street_number',
        ),
        street_box: _t(
          'components.ui.json_form.form_labels.fields.address.street_box',
        ),
        city: _t('components.ui.json_form.form_labels.fields.address.city'),
        zip_code: _t(
          'components.ui.json_form.form_labels.fields.address.zip_code',
        ),
      },
      true_false: {
        trueLabel: _t('components.ui.json_form.form_labels.fields.true_label'),
        falseLabel: _t(
          'components.ui.json_form.form_labels.fields.false_label',
        ),
      },
      select: {
        placeholder: _t(
          'components.ui.json_form.form_labels.fields.select.placeholder',
        ),
      },
      multiple_input_group: {
        actions: {
          actions_title: _t(
            'components.ui.json_form.form_labels.fields.multiple_input_group.actions.actions_title',
          ),
          add_label: _t(
            'components.ui.json_form.form_labels.fields.multiple_input_group.actions.add_label',
          ),
          remove_label: _t(
            'components.ui.json_form.form_labels.fields.multiple_input_group.actions.remove_label',
          ),
          empty_label: _t(
            'components.ui.json_form.form_labels.fields.multiple_input_group.actions.empty_label',
          ),
        },
      },
      upload: {
        no_options: _t(
          'components.ui.json_form.form_labels.fields.upload.no_options',
        ),
        select_file: _t(
          'components.ui.json_form.form_labels.fields.upload.select_file',
        ),
        remove_file: _t(
          'components.ui.json_form.form_labels.fields.upload.remove_file',
        ),
        cancel: _t('components.ui.json_form.form_labels.fields.upload.cancel'),
        confirm: _t(
          'components.ui.json_form.form_labels.fields.upload.confirm',
        ),
      },
    },
  };
};

export const getFormOptions = (currentLocale, requestId = 'coucou') => {
  return {
    showFormCompleteMessage: false,
    showFormErrorMessage: true,
    renderingErrors: {
      showError: true,
      showSchema: !PRODUCTION,
      shallowEqual: !PRODUCTION,
    },
    debugErrors: false,
    algolia: {
      appId: ALGOLIA_APPLICATION_ID,
      apiKey: ALGOLIA_API_KEY,
    },
    googleMap: {
      apiKey: GOOGLE_API_KEY,
      lang: currentLocale,
    },
    carapi: {
      endpoint: CARAPI_DOMAIN,
    },
    eurotax_yuzzu: {
      endpoint: EUROTAX_YUZZU_DOMAIN,
      fields: {
        door: { table: true, resume: true, position: 9 },
        engine_capacity: { table: false, resume: true, position: 11 },
        fuel_type: { table: false, resume: true, position: 7 },
        transmission_type: { table: false, resume: true, position: 8 },
        hp: { table: true, resume: true, position: 6 },
        imp_begin: null,
        imp_end: null,
        kw: { table: true, resume: true, position: 5 },
        market: null,
        model_name: null,
        name: { table: true, resume: true, position: 3 },
        nat_code: { table: false, resume: true, position: 12 },
        seat: { table: false, resume: true, position: 10 },
        seat_max: null,
        sportive: null,
        tmc: null,
        txt_body_co_int_cd2: null,
        txt_fuel_type_cd2: null,
        txt_trans_type_cd2: null,
        value_without_tax: {
          table: true,
          resume: true,
          position: 7,
          format: 'price',
        },
        veh_type: null,
        brand: { table: false, resume: true, position: 1 },
        model: { table: false, resume: true, position: 2 },
      },
    },
    eurotax_yuzzu_tc8: {
      endpoint: EUROTAX_YUZZU_TCAR8_DOMAIN,
    },
    eurotax: {
      endpoint: EUROTAX_DOMAIN,
      fields: {
        eurotaxID: { table: false, resume: false },
        brandId: { table: false, resume: false },
        brandLabel: { table: false, resume: true },
        modelId: { table: false, resume: false },
        modelLabel: { table: false, resume: true },
        constructionYear: { table: false, resume: true },
        fuelTypeEurotaxID: { table: false, resume: false },
        fuelTypeName: { table: false, resume: true },
        fuelTypeMainframeID: { table: false, resume: false },
        vehicleTypeCode: { table: false, resume: false },
        taxHP: { table: false, resume: false },
        bodyType: { table: false, resume: false },
        segmentTypeInternational: { table: false, resume: false },
        segmentTypeNational: { table: false, resume: false },
        sportCarCat: { table: false, resume: false },
        powerKW: { table: false, resume: true },
        powerHP: { table: false, resume: true },
        powerHybKW: { table: false, resume: true },
        transmissionId: { table: false, resume: false },
        transmissionLabel: { table: false, resume: true },
        tractionId: { table: false, resume: false },
        tractionLabel: { table: false, resume: false },
        gears: { table: false, resume: true },
        seats: { table: false, resume: true },
        seatMax: { table: false, resume: true },
        doors: { table: false, resume: true },
        amountCatalogueValueWithOutOptionsExclVAT: {
          table: false,
          resume: true,
          format: 'price',
        },
        amountCatalogueValueWithOutOptionsExclVATStartDate: {
          table: false,
          resume: false,
          format: 'date',
        },
        amountCatalogueValueWithOutOptionsExclVATEndDate: {
          table: false,
          resume: false,
          format: 'date',
        },
        version: { table: false, resume: true },
        typeVehicleId: { table: false, resume: false },
        typeVehicleName: { table: false, resume: true },
        concatOutput: { table: true, resume: false },
      },
    },
    upload: {
      endpoint: `${PROTOCOL}://${API_DOMAIN}/requests/${requestId}/enriched_uploads`,
      authToken: getAuthorizationToken(),
      removeFile: (fileToRemove) => {
        console.log('removeFile', fileToRemove);
      },
    },
    provider: {
      bce: {
        labels: {
          error_occurred: t(
            'components.ui.json_form.provider.bce.labels.error_occurred',
          ),
          activities: t(
            'components.ui.json_form.provider.bce.labels.activities',
          ),
          loading: t('components.ui.json_form.provider.bce.labels.loading'),
          search: t('components.ui.json_form.provider.bce.labels.search'),
          clear: t('components.ui.json_form.provider.bce.labels.clear'),
          no_result: t('components.ui.json_form.provider.bce.labels.no_result'),
          addresses: t('components.ui.json_form.provider.bce.labels.addresses'),
          result_for: t(
            'components.ui.json_form.provider.bce.labels.result_for',
          ),
          juridical_form: t(
            'components.ui.json_form.provider.bce.labels.juridical_form',
          ),
          vat_clean: t('components.ui.json_form.provider.bce.labels.vat_clean'),
          vat_formatted: t(
            'components.ui.json_form.provider.bce.labels.vat_formatted',
          ),
          company_name: t(
            'components.ui.json_form.provider.bce.labels.company_name',
          ),
          start_date: t(
            'components.ui.json_form.provider.bce.labels.start_date',
          ),
          phone_number: t(
            'components.ui.json_form.provider.bce.labels.phone_number',
          ),
          fax_number: t(
            'components.ui.json_form.provider.bce.labels.fax_number',
          ),
          email: t('components.ui.json_form.provider.bce.labels.email'),
          website: t('components.ui.json_form.provider.bce.labels.website'),
          establishment_units: t(
            'components.ui.json_form.provider.bce.labels.establishment_units',
          ),
          financial_meta: {
            title: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.title',
            ),
            capital: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.capital',
            ),
            exceptional_fiscal_year_begin: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.exceptional_fiscal_year_begin',
            ),
            exceptional_fiscal_year_end: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.exceptional_fiscal_year_end',
            ),
            annual_meeting_month: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.annual_meeting_month',
            ),
            fiscal_year_end_day: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.fiscal_year_end_day',
            ),
            fiscal_year_end_month: t(
              'components.ui.json_form.provider.bce.labels.financial_meta.fiscal_year_end_month',
            ),
          },
        },
        url: `${DATA_BE_API}?number=%{number}`,
        authToken: getAuthorizationToken(),
      },
    },
  };
};
